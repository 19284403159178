import { Canvas } from "@react-three/fiber";
import Experience from "./Experience.jsx";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    let loader = document.querySelector("#root .loader");

    setTimeout(() => {
      loader = document.querySelector(".loader");
    }, 10);
    

    window.onload = () => {
      setTimeout(() => {
        loader.style.opacity = "0";
        setTimeout(() => {
          loader.remove();
        }, 1000);
      }, 1200);
    };
  }, []);
  return (
    <>
      <div className="loader">Loading...</div>
      <Canvas
        camera={{
          fov: 45,
          near: 0.1,
          far: 2000,
          // position: [-3, 1.5, 4],
          // !
          position: [5.5, 0.77, 7],
          rotation: [-0.11, 0.7, 0.01],
          // !
          // position: [-0.45, 0.77, 1.95],
          // rotation: [-0.11, 0.06, 0.01]
        }}
      >
        <Experience />
      </Canvas>
      <div className="switcher">
        <span
          className="yt"
          link="https://www.youtube.com/embed/WfrR6VSLNkU?autoplay=1&mute=1"
          clr="#ff7777"
        ></span>
        <span
          className="joe"
          link="https://yusufgamal.com/html/"
          clr="#00e5fa"
        ></span>
        <span className="zoom in">
          <b>FOCUS ON SCREEN</b>
          <img src="/arrow.png" />
        </span>
      </div>
      <a href="https://www.youtube.com/watch?v=Ntf1qukIxmE" target="_blank">
        <img src="/palestine.jpg" alt="Free Palestine" className="ps" />
      </a>
      <div className="shadow"></div>
      <audio src="/fallen.mp3" autoPlay loop></audio>
    </>
  );
}

export default App;
