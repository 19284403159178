import {
  ContactShadows,
  Environment,
  Float,
  Html,
  // OrbitControls,
  PresentationControls,
  Text,
  useGLTF,
} from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { EffectComposer, Noise } from "@react-three/postprocessing";
import gsap from "gsap";
// import { useControls } from "leva";
import { BlendFunction } from "postprocessing";
import { useEffect, useRef, useState } from "react";

export default function Experience() {
  const macbook = useGLTF("/macbook.gltf");
  const noiseRef = useRef();

  const [rectColor, setRectColor] = useState("#000");

  // const { position, rotation } = useControls({
  //   position: {
  //     value: { x: -3, y: 1.5, z: 4 },
  //     step: 0.05,
  //   },
  //   rotation: {
  //     value: { x: 0, y: 0, z: 0 },
  //     step: 0.01,
  //   },
  // });

  const { camera } = useThree();

  function zoomIn() {
    let zoomIcon = document.querySelector("span.zoom");

    zoomIcon.classList.remove("in");
    zoomIcon.classList.add("out");

    gsap
      .timeline({ defaults: { duration: 2.7 } })
      .to(camera.position, {
        x: -0.5,
        y: 0.77,
        z: 1.95,
      })
      .to(
        camera.rotation,
        {
          x: -0.11,
          y: 0.06,
          z: 0.01,
        },
        "<"
      );
  }

  function zoomOut() {
    let zoomIcon = document.querySelector("span.zoom");

    zoomIcon.classList.add("in");
    zoomIcon.classList.remove("out");

    gsap
      .timeline({ defaults: { duration: 1.7 } })
      .to(camera.position, {
        x: -3,
        y: 1.5,
        z: 4,
      })
      .to(
        camera.rotation,
        {
          x: -0.3587706702705722,
          y: -0.612232348422086,
          z: -0.2122644493432918,
        },
        "<"
      );
  }

  useEffect(() => {
    // let noise = document.querySelector(".noiseWrapper .noiseDiv");
    let noise = noiseRef.current;

    setTimeout(() => {
      noise = noiseRef.current;
      noise.style.display = "block";
    }, 10);

    setRectColor("#000");

    setTimeout(() => {
      zoomOut();
    }, 200);

    setTimeout(() => {
      noise.style.display = "none";
      setRectColor("#00e5fa");
    }, 1200);

    setTimeout(() => {
      document.querySelector(".switcher").style.left = "5%";

      setTimeout(() => {
        document.querySelector(".ps").style.bottom = "-30px";
      }, 200);
    }, 1000);

    let ytIcon = document.querySelector(".switcher span.yt");
    let joeIcon = document.querySelector(".switcher span.joe");
    let zoomIcon = document.querySelector(".switcher span.zoom");
    let iframe = document.querySelector("iframe");

    setTimeout(() => {
      iframe = document.querySelector("iframe");
    }, 10);

    [ytIcon, joeIcon].forEach((el) => {
      el.addEventListener("click", (e) => {
        let link = e.target.getAttribute("link");
        let color = e.target.getAttribute("clr");

        noise.style.display = "block";

        setRectColor("#000");

        setTimeout(() => {
          noise.style.display = "none";
          setRectColor(color);
        }, 1200);

        iframe.src = link;
      });
    });

    ytIcon.addEventListener("click", zoomIn);
    joeIcon.addEventListener("click", zoomOut);

    zoomIcon.addEventListener("click", () => {
      if (zoomIcon.classList.contains("in")) {
        zoomIn();
      } else if (zoomIcon.classList.contains("out")) {
        zoomOut();
      }
    });
  }, []);

  return (
    <>
      <color args={["#111"]} attach="background" />

      <Environment preset="city" />

      {/* <OrbitControls makeDefault /> */}

      <EffectComposer>
        <Noise
          // premultiply
          blendFunction={BlendFunction.SOFT_LIGHT}
        />
      </EffectComposer>

      <PresentationControls
        global
        rotation={[0.13, 0.1, 0]}
        polar={[-0.4, 0.2]}
        azimuth={[-1, 0.75]}
        config={{ mass: 2, tension: 400 }}
        // snap
        snap={{ mass: 4, tension: 400 }}
      >
        <Float rotationIntensity={0.5}>
          <rectAreaLight
            width={2.5}
            height={1.65}
            intensity={65}
            color={rectColor}
            rotation={[0.07, Math.PI, 0]}
            position={[0, 0.55, -1.15]}
          />
          <primitive object={macbook.scene} position-x={-0.5} position-y={-1.2}>
            <Html
              transform
              wrapperClass="htmlScreen"
              distanceFactor={1.17}
              position={[0, 1.56, -1.4]}
              rotation-x={-0.256}
            >
              <iframe
                src="https://yusufgamal.com/html/"
                frameBorder="0"
              ></iframe>
            </Html>
            <Html
              transform
              wrapperClass="noiseWrapper"
              distanceFactor={1.18}
              position={[0, 1.56, -1.4]}
              rotation-x={-0.256}
            >
              <div ref={noiseRef} className="noiseDiv"></div>
            </Html>
          </primitive>

          <Text
            font="/oswald-regular.woff"
            fontSize={0.3}
            position={[3.7, 0, 0.75]}
            rotation-y={-1.25}
            maxWidth={4}
            textAlign="left"
          >
            HEY BUDDY, SORRY TO TELL YOU THAT THIS PORTFOLIO IS UNDER
            MAINTENANCE! BUT, YOU CAN TAKE A LOOK AT THIS SIMPLIFIED VERSION OR
            WATCH THE GOAT ON YOUTUBE!
          </Text>
        </Float>
        <ContactShadows position-y={-1.4} opacity={0.4} scale={3} blur={2.4} />
      </PresentationControls>
    </>
  );
}

useGLTF.preload("/macbook.gltf");
